import styled from "styled-components";

export const MainContainer = styled.div`
  height: 90vh;
  @media(max-width: 768px){
    height: auto;
    margin-bottom: 14rem;
  }
`;

export const BoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem 5rem;
  gap: 2rem;

  @media(max-width: 768px){
   display: block;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 300px;
  padding: 0rem ;
  gap: 1rem;

    @media(max-width: 768px){
       padding: 0;
       display: block;
  }
`;

export const TipBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 300px;
  padding: 0rem 8rem;
  
  @media(max-width: 768px){
    flex-direction: column;
    height: auto;
    padding: 0;
  }
`;

export const BoxTitle = styled.h2`
  font-weight: 400;
  font-size: 1.25rem;
  color: #217B7F;
  margin-bottom: 1.5rem;
`;

export const BoxSecondTitle = styled.h2`
  font-weight: 600;
  font-size: 4rem;
  width: 350px;
  color: #02393e;
  margin-bottom: 2rem;

`;

export const BoxParagraph = styled.div`
width: auto;
  font-weight: 400;
  color: #02393E;
  font-size: 1.563rem;
  background-color: #F6F6F6;
  padding: 1rem;
  border-radius: 1.313rem;
  margin-bottom: 1rem;
`;

export const TextParagraph = styled.span`
  font-weight: 400;
  color: #02393E;
  font-size: 1.8rem;
  margin-top: 2rem;
  line-height: 30px;
`;

export const TipContainer = styled.div`
display: flex;
  gap: 1rem;
`

export const InfoContainer = styled.div`
  // max-width: 100%;
  margin: 0 auto;
  background-color: #02393e;
  padding: 2rem 10rem;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  height: 372px;
   @media(max-width: 768px){
    flex-direction: column;
    height: auto;
     padding: 2rem 2rem;
  }
`;

export const Cutout = styled.div`
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 25px;
  background-color: #fff;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Checkmark = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  z-index: 3;
`;

export const TitleContainer = styled.div``;

export const ButtonOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 3.938rem;
  background-color: #6bc1c6;
  color: #ffffff;
  border-radius: 3rem;
  border: none;
  cursor: pointer;
`;

export const BoxOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26rem;
  padding: 0rem 1rem;
  height: 3.938rem;
  background-color: #02393E;
  color: #fff;
  border-radius: 3rem;
  border: none;
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const ButtonIcon = styled.img`
  height: 4rem;
  margin: 0;
`;

export const PhraseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.75rem;
  color: #fff;
  font-weight: 300;
  line-height: 2.625rem;
  width: 60%;
    @media(max-width: 768px){
    width: 100%;
      gap: 2rem;
      width: 100%;
      text-align: center;
      align-items: center;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepContainerText = styled.h2`
  color: #fff;
  font-weight: 500;
  font-size: 5rem;
  width: 80%;
    @media(max-width: 768px){
    width: 100%;
     font-size: 4rem;
     text-align: center;
  }
`;

export const StepContainerSpan = styled.span`
  font-size: 4.62rem;
  font-weight: 700;
  color: #6bc1c6;
`;
