import logotype from "../../assets/logo.svg";
import upArrow from "../../assets/icons/up-arrow.svg";
import * as Styles from "./styles.js";
import Burger from "../../assets/mobile/Menu.svg";
import Close from "../../assets/mobile/close.svg";
import MobileLogo from "../../assets/mobile/LogoVerde 1.svg";
import { useState } from "react";

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const phoneNumber = '+5513978003685';
  const message = 'Olá, gostaria de mais informações.';

  const handleContact = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); 
    }
  };


  return (
    <Styles.NavContainer>
      <Styles.LogoContainer src={logotype} alt="Logotype image example." />
      <Styles.LogoMobile src={MobileLogo} alt="Logotype image for mobile." />
      <Styles.BurgerIcon src={isMenuOpen ? Close : Burger} alt="Menu icon" onClick={toggleMenu} />
      <Styles.NavOptions isOpen={isMenuOpen}>
        <Styles.NavOption onClick={() => scrollToSection('infoSection')}>O que é ser Codice?</Styles.NavOption>
        <Styles.NavOption onClick={() => scrollToSection('projectsSection')}>Portfólio</Styles.NavOption>
        <Styles.NavOption onClick={() => scrollToSection('usSection')}>Quem somos?</Styles.NavOption>
        <Styles.NavOption>
            <Styles.ButtonOption onClick={handleContact}>Entre em contato<Styles.ButtonIcon src={upArrow}/></Styles.ButtonOption>
        </Styles.NavOption>
      </Styles.NavOptions>
    </Styles.NavContainer>
  );
}

export default Navbar;
