import * as Styles from "./styles";
import upArrow from "../../assets/icons/up-arrow.svg";
import forward from "../../assets/icons/Forward.svg";
import frame from "../../assets/Frame.svg";
import { useState } from "react";


function Intro() {
  const phoneNumber = '+5513978003685';
  const message = 'Olá, gostaria de mais informações.';
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleContact = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); 
    }
  };
  return (
    <Styles.IntroContainer>
      <Styles.TextContainer>
      
        <Styles.IntroText>
          Seu sucesso a um <span style={{ color: '#217B7F' }}>software de distância! ;)</span>
        </Styles.IntroText>
        <Styles.SecondaryText>Algum CTA bem bacana exaltando a empresa com alguma
        frase bem clichê</Styles.SecondaryText>
    
        <Styles.ButtonsContainer>
        <Styles.ButtonOption onClick={handleContact}>Entre em contato</Styles.ButtonOption>
        <Styles.SecondaryButton onClick={() => scrollToSection('infoSection')}>Conheça a Codice<Styles.SecondaryIcon src={forward}/></Styles.SecondaryButton>
        </Styles.ButtonsContainer>
      </Styles.TextContainer>
      <Styles.AnimationContainer>
        <Styles.Frame src={frame} />
      </Styles.AnimationContainer>
    </Styles.IntroContainer>
  );
}
export default Intro;
