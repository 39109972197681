import styled from 'styled-components'
import Navbar from './components/navbar'
import Intro from './components/intro'
import Info from './components/info'
import Projects from './components/projects'
import Contact from './components/contact'
import Footer from './components/footer'

const MainContainer = styled.div`
`

function App() {
  return (
      <MainContainer>
        <Navbar />
        <Intro />
        <Info />
        <Projects />
        <Contact />
        <Footer />
      </MainContainer>
  )
}

export default App
