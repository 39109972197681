import * as Styles from "./styles";
import Frame from "../../assets/Frame2.svg";
import { useState } from "react";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_8llsbs1",
        "template_ri0n1zh",
        formData,
        "yETLLzIsZiJ_4ULre"
      )
      .then(
        (response) => {
          console.log("Mensagem enviada!", response.status, response.text);
          notifySuccess();
        },
        (error) => {
          console.log("Erro ao enviar...", error);
        }
      );
  };

  const notifySuccess = () =>{
    toast.success("Mensagem enviada com sucesso!", {
        className: 'toast-success'
    });
    console.log('Armed and ready')
  } 
  return (
    <Styles.MainContainer>
      <ToastContainer />
      <Styles.ContactSection>
        <Styles.Frame>
          <Styles.FrameSvg src={Frame} />
        </Styles.Frame>
        <Styles.FormContainer>
          <div>
            <Styles.FormSpan>Ainda tem dúvidas?</Styles.FormSpan>
            <Styles.FormTitle>
              Pergunte para a <br></br>gente
            </Styles.FormTitle>
          </div>
          <Styles.Form onSubmit={handleSubmit}>
            <Styles.FormRow>
              <Styles.FormColumn>
                <Styles.Label>Name</Styles.Label>
                <Styles.Input
                  type="text"
                  name="name"
                  placeholder="Nome"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Styles.FormColumn>
              <Styles.FormColumn>
                <Styles.Label>Sobrenome</Styles.Label>
                <Styles.Input
                  type="text"
                  name="lastName"
                  placeholder="Sobrenome"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Styles.FormColumn>
            </Styles.FormRow>
            <Styles.Label>E-mail</Styles.Label>
            <Styles.Input
              type="email"
              name="email"
              placeholder="seuemail@email.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Styles.Label>Mensagem</Styles.Label>
            <Styles.TextArea
              name="message"
              placeholder="Mande sua melhor pergunta"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <Styles.FormRow>
              <Styles.FormColumn>
                <Styles.FormButton type="submit">
                  Enviar Mensagem
                </Styles.FormButton>
              </Styles.FormColumn>
            </Styles.FormRow>
          </Styles.Form>
        </Styles.FormContainer>
      </Styles.ContactSection>
    </Styles.MainContainer>
  );
}

export default Contact;
