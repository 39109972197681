import styled from 'styled-components';

export const NavContainer = styled.nav`
    padding: 0rem 10rem;
    margin: 2rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    min-width: 100%;

    @media (max-width: 768px){
        position: sticky;
        margin-top: 1rem;
        padding: 0rem 2rem; 
    }
`

export const LogoContainer = styled.img`
    width: 14.75rem;
     @media (max-width: 768px) {
    display: none;
  }
`

export const NavOptions = styled.ul`
    display: flex;
    gap: 2.5rem;
    list-style: none;
    align-items: center;

    @media(max-width: 768px){
           display: ${props => (props.isOpen ? 'flex' : 'none')};
           flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 1rem;
    }
`

export const NavOption = styled.li`
    font-weight: 400;
    font-size: 1.563rem;
    color: #02393E;
    cursor: pointer;
     @media (max-width: 768px) {
    margin: 1rem 0;
  }
`

export const ButtonOption = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18rem;
    height: 3.938rem;
    background-color: #02393E;
    color: #ffffff;
    border-radius: 3rem;
    border: none;
    cursor: pointer;
`

export const ButtonIcon = styled.img`
   height: 4rem;
   margin: 0;
`

export const LogoMobile = styled.img`
display: none;
width: 45px;
height: 55px;

@media (max-width: 768px){
    display: block;
}
`

export const BurgerIcon = styled.img`
  display: none;
  cursor: pointer;
  height: 30px;

  @media (max-width: 768px) {
    display: block;
  }
`;