import * as Styles from "./styles.js";
import firstLogo from "../../assets/logotypes/download.svg";
import secondLogo from "../../assets/logotypes/LOGO-PNG-1024x207 1.svg";
import thirdLogo from "../../assets/logotypes/XJUR-LOGO_ORIGINAL-2048x1677 1.svg";
import thirdLogoWhite from "../../assets/logotypes/xjurbranco.svg";
import secondLogoWhite from "../../assets/logotypes/infolockbranco.svg";
import firstLogoWhite from "../../assets/logotypes/lgpdbranco.svg";
import wpp from "../../assets/icons/WhatsApp.svg";


function Projects() {
  const firstLink = 'https://xjur.com.br';
  const secondLink = 'https://infolocklgpd.com.br/';
  const thirdLink = 'https://lgpdlock.com.br/';

  const phoneNumber = '+5513978003685';
  const message = 'Olá, gostaria de mais informações.';

  const handleContact = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }
  return (
    <Styles.MainContainer id="projectsSection">
      <Styles.ProjectsSection>
        <Styles.SectionTitle>Nossos Projetos</Styles.SectionTitle>
        <Styles.Title>Nossos Projetos</Styles.Title>
        <Styles.ProjectsDemo>
          <Styles.ProjectLink href={firstLink} target="_blank" rel="noopener noreferrer" hoverLogo={thirdLogoWhite}>
          <Styles.Project >
            <Styles.ProjectLogo src={thirdLogo} className="logo thirdLogo"/>
          </Styles.Project>
          <Styles.MobileDivider>
          </Styles.MobileDivider>
          </Styles.ProjectLink>
          <Styles.ProjectLink href={secondLink} target="_blank" rel="noopener noreferrer" hoverLogo={secondLogoWhite}>
          <Styles.Project >
            <Styles.ProjectLogo src={secondLogo} className="logo secondLogo"/>
          </Styles.Project>
          </Styles.ProjectLink>
          <Styles.ProjectLink href={thirdLink} target="_blank" rel="noopener noreferrer" hoverLogo={firstLogoWhite}>
          <Styles.Project >
            <Styles.ProjectLogo src={firstLogo} className="logo firstLogo"/>
          </Styles.Project>
          </Styles.ProjectLink>
        </Styles.ProjectsDemo>
      </Styles.ProjectsSection>
      <Styles.InfoContainer>
        <Styles.StepContainerText>
          Pronto Para <br></br> se{" "}  
          <Styles.StepContainerSpan>tornar Codice?</Styles.StepContainerSpan>
        </Styles.StepContainerText>

        <Styles.PhraseContainer>
          <div style={{ textAlign: "center" }}>
          Entre em contato agora e descubra como podemos ajudar sua<br></br>empresa a alcançar novos patamares de sucesso digital.
          </div>
          <Styles.ButtonOption onClick={handleContact}>
            Nos chame no WhatsApp
            <Styles.ButtonIcon src={wpp} />
          </Styles.ButtonOption>
        </Styles.PhraseContainer>
      </Styles.InfoContainer>
    </Styles.MainContainer>
  );
}

export default Projects;
