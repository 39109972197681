import styled from "styled-components";

export const FooterContainer = styled.div`
    background-color: #02393E;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 8rem;
    gap: 2rem;
    height: 425px;

    @media(max-width: 768px){
        padding: 4rem 0rem;
        height: auto;
    }
    
`
export const FooterContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8rem;
    height: 100%;
    padding-bottom: 5rem;
    border-bottom: 1px solid #fff;
    @media(max-width: 768px){
     flex-direction: column;  
     gap: 2rem; 
      text-align: center;
    }
`

export const FooterLogo = styled.img`
    width: 12rem;
`
export const Icons = styled.div`
    display: flex;
    gap: 2rem;
`

export const Icon = styled.img`
width: 25px;
height: 25px;
`

export const FooterParagraph = styled.p`
    color: #fff;
    font-weight: 400;
    font-size: 1.563rem;

    @media(max-width: 768px){
        font-size: 1.4rem;
        padding: 0rem 2rem 2rem 2rem;
        border-bottom: 1px solid #fff;
    }
`

export const FooterColumn = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: right;
    @media(max-width: 768px){
        align-items: center;
    }
  
`

export const ColumnTitle = styled.span`
    color: #6BC1C6;
    font-weight: 400;
    font-size: 1.563rem;
`

export const Rights = styled.div``

export const RightsText = styled.span`
    color: #fff;
    font-weight: 400;
    font-size: 12px;
`