import * as Styles from "./styles";
import upArrow from "../../assets/icons/up-arrow.svg";
import nextPage from "../../assets/icons/nextPage.svg";


function Info(){
  const phoneNumber = '+5513978003685';
  const message = 'Olá, gostaria de mais informações.';

  const handleContact = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }
  return (
    <Styles.MainContainer id="infoSection">
      <Styles.InfoContainer>
        <Styles.Cutout>
          <Styles.Checkmark src={nextPage} />
        </Styles.Cutout>
        <Styles.TitleContainer>
          <Styles.StepContainer>
            <Styles.StepContainerText>
              O que é ser{" "}
              <Styles.StepContainerSpan>Codice?</Styles.StepContainerSpan>A
              gente explica! :)
            </Styles.StepContainerText>
          </Styles.StepContainer>
        </Styles.TitleContainer>
        <Styles.PhraseContainer>
          <div>
            Transformar qualquer ideia em realidade digital. Inovação, agilidade
            e competitividade em cada linha de código.{" "}
            <span style={{ fontWeight: "600" }}>Isso é ser Codice.</span>
          </div>
          <Styles.ButtonOption onClick={handleContact}>
            Descubra-nos
            <Styles.ButtonIcon src={upArrow} />
          </Styles.ButtonOption>
        </Styles.PhraseContainer>
      </Styles.InfoContainer>
      <Styles.BoxesContainer id="usSection">
        <Styles.TipBox>
          <Styles.BoxTitle>Quem somos nós?</Styles.BoxTitle>
          <Styles.BoxSecondTitle>Quem somos nós?</Styles.BoxSecondTitle>
          <Styles.TipContainer>
            <Styles.BoxParagraph>Criatividade</Styles.BoxParagraph>
            <Styles.BoxParagraph>Experiência</Styles.BoxParagraph>
          </Styles.TipContainer>
          <Styles.TipContainer>
            <Styles.BoxParagraph>Inovação</Styles.BoxParagraph>
            <Styles.BoxParagraph>Programação</Styles.BoxParagraph>
          </Styles.TipContainer>
        </Styles.TipBox>
        <Styles.TextBox>
          <Styles.TextParagraph>
          Somos uma equipe de especialistas apaixonados por tecnologia
e programação. Na Codice, combinamos criatividade, experiência
e expertise para oferecer soluções digitais excepcionais para nossos
clientes.
          </Styles.TextParagraph>
          <Styles.TextParagraph>
          Somos mais do que tecnologia. Somos inovação em ação, agilidade
em desenvolvimento e preços competitivos que desafiam o mercado.
Escolha levar sua empresa para o próximo nível.
          </Styles.TextParagraph>
          <Styles.BoxOption onClick={handleContact}>
            Alavancar minha empresa
            <Styles.ButtonIcon src={upArrow} />
          </Styles.BoxOption>
        </Styles.TextBox>
      </Styles.BoxesContainer>
    </Styles.MainContainer>
  );
}

export default Info;
