import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 90vh;
`

export const ProjectsSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
    padding: 4rem 5rem;
    margin-bottom: 2rem;
    @media(max-width: 768px){
      
      align-items: start;
    }
`

export const SectionTitle = styled.span`
    font-weight: 400;
    font-size: 1.25rem;
    color: #217B7F;
    margin-bottom: 1rem;

    @media(max-width: 768px){
      white-space: nowrap;
      text-align: left;
    }
`

export const Title = styled.h2`
    color: #02393E;
    font-weight: 600;
    font-size: 4rem;
     margin-bottom: 2rem;
     @media(max-width: 768px){
       font-size: 3rem;
     }
`

export const ProjectsDemo = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
     @media(max-width: 768px){
      flex-direction: column;
      gap: 2rem;
    }
`

export const Project = styled.div`
    width: 300px;
  
    border-radius: 2.563rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 6rem;
    cursor: pointer;
   
`
export const ProjectLogo = styled.img`
    width: 18rem;
    heigth: 3.68rem;
`

export const InfoContainer = styled.div`
  // max-width: 100%;
  margin: 0 auto;
  background-color: #02393e;
  padding: 4rem 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   @media(max-width: 768px){
      padding: 4rem 2rem;
    }
`;

export const Checkmark = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  z-index: 3;
`;

export const TitleContainer = styled.div``;

export const ButtonOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 403px;
  height: 3.938rem;
  background-color: #6BC1C6;
  color: #ffffff;
  border-radius: 3rem;
  border: none;
  cursor: pointer;
  font-size: 21px;
  @media(max-width: 768px){
      font-size: 16px;
      width: 80%;
    }
`;

export const BoxOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26rem;
  padding: 0rem 1rem;
  height: 3.938rem;
  background-color: #02393E;
  color: #fff;
  border-radius: 3rem;
  border: none;
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const ButtonIcon = styled.img`
  height: 36px;
  width: 36px;
  margin: 0;
`;

export const PhraseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.563rem;
  color: #fff;
  font-weight: 300;
  line-height: 2.625rem;
 align-items: center;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
align-items: center;
  justify-content: center;
`;

export const StepContainerText = styled.h2`
  color: #fff;
  font-weight: 500;
  font-size: 4rem;
  text-align: center;
  margin: 1rem 0rem;
`;

export const StepContainerSpan = styled.span`
  font-size: 4rem;
  font-weight: 700;
  color: #6bc1c6;
`;

export const ProjectLink = styled.a`
  width: 300px;
    background-color: #F6F6F6;
    border-radius: 2.563rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 6rem;
    cursor: pointer;
     transition: all ease 300ms;

    &:hover{
        background-color: #217B7F;
    }
      
      &:hover .thirdLogo{
      content: url(${props => props.hoverLogo});
    }
      &:hover .secondLogo{
      content: url(${props => props.hoverLogo});
    }
      &:hover .firstLogo{
      content: url(${props => props.hoverLogo});
    }
    @media(max-width: 768px){
      height: 300px;
    }
   
`

export const MobileDivider = styled.div`
  display: flex;
`