import styled from "styled-components";

export const IntroContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display:flex;
    align-items: center;
    justify-content: center; 
    height: 100vh;   
    gap: 2rem;

    @media(max-width: 768px){
        flex-direction: column;
        padding: 0rem 2rem;
         height: 100vh; 
         width: 100vw; 
         margin-top: 4rem;
         margin-bottom: 2rem;
    }
`
export const TextContainer = styled.div`
    width: 50%;
     @media(max-width: 768px){
        width: 100%;
        display:flex;
        flex-direction: column;
        gap: 1rem;
    }
`
export const IntroText = styled.h1`
    color: #02393E;
    // min-width: 100%;
    // min-height: 300px;
    font-weight: 600; 
    font-size: 4vw;

       @media(max-width: 768px){
        font-size: 40px;
        margin-top: 2rem;
    }
    
`

export const SecondaryText = styled.p`
    margin-bottom: 3rem;
    color: #02393E;
    font-size: 25px;
    font-weight: 400;
        @media(max-width: 768px){
        font-size: 18px;
        margin-top: 1rem;
        
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    float: left;
    gap: 4rem;

`
export const ButtonOption = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18rem;
    height: 3.938rem;
    background-color: #02393E;
    color: #ffffff;
    border-radius: 3rem;
    border: none;
    cursor: pointer;
`

export const ButtonIcon = styled.img`
   height: 4rem;
   margin: 0;
`

export const SecondaryButton = styled.button`
background-color: transparent;
border: none;    
display: flex;
align-items: center;
color: #02393E;
cursor: pointer;
      @media(max-width: 768px){
        font-size: 1.5rem;
  
        white-space: nowrap;
       
    }
`

export const SecondaryIcon = styled.img`

`

export const AnimationContainer = styled.div`
 width: 50%;
  @media(max-width: 768px){
    width: 100%;
   }


`

export const Frame = styled.img`
   width: 500px;
   height: 500px;

   @media(max-width: 768px){
    width: 100%;
   height: 100%;

   }

`
