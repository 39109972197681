import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 70vh;

  @media(max-width: 768px){
    height: auto;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  align-items: center;
  padding: 4rem 5rem;
  gap: 0rem;

  @media(max-width: 768px){
    flex-direction: column;
    gap: 2rem;
  }
`;

export const Frame = styled.div`
  
`;

export const FrameSvg = styled.img`
      width: 80%;
    height: 100%;
    
    @media(max-width: 768px){
      width: 100%;
    }
`;

export const FormContainer = styled.div`
width: 50%;

@media(max-width: 768px){
  width: 100%;
}
`;

export const Form = styled.form`
display: flex;
flex-direction: column;
gap: 0.8rem;
`;

export const FormSpan = styled.span`
font-size: 1.563rem;
color: #217B7F;
font-weight: 400;
`;

export const FormTitle = styled.h2`
font-size: 4rem;
color: #02393E;
font-weight: 600;
margin: 0rem 0rem 2.5rem 0rem;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.label`
 display: block;
 color: #217B7F;
 font-size: 1.563rem;
 margin-bottom: 0.2rem;
 font-weight: 600;
`;

export const Input = styled.input`
  border: 1px solid #217B7F;
  border-radius: 1rem;
  padding: 1rem;
  color: #217B7F;
  font-weight: 400;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 120px; 
  resize: none;
  padding: 0.5rem;
  font-size: 2rem;
  color: #217B7F;
  font-weight: 400;
  border: 1px solid #217B7F;
 border-radius: 1rem;
  &::placeholder{
    font-size: 1.3rem;
    padding: 1rem;
  }
`;

export const FormButton = styled.button`
border: none;
background-color: #02393E;
color: #FFFFFF;
font-weightt: 400;
font-size: 1.5rem;
padding: 1rem;
border-radius: 0.8rem;
cursor: pointer;
transition: all ease 200ms;

&:hover{
  transform: scale(1.05);
}
`