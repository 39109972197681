import * as Styles from "./styles";
import Logo from "../../assets/logobranco.svg";
import FbIcon from "../../assets/icons/Facebook.svg";
import GhIcon from "../../assets/icons/GitHub.svg";
import IgIcon from "../../assets/icons/Instagram.svg";
import XIcon from "../../assets/icons/TwitterX.svg";
import { useState } from "react";

function Footer() {
    const firstExternalLink = ""; 
    const secondExternalLink = ""; 
    const thirdExternalLink = "https://www.instagram.com/codice_tech/"; 
    const fourthExternalLink = ""; 
    const [isMenuOpen, setMenuOpen] = useState(false);

    
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); 
    }
  };

  return (
    <Styles.FooterContainer>
      <Styles.FooterContent>
        <Styles.FooterColumn>
          <Styles.FooterLogo src={Logo} />
          <Styles.FooterParagraph>
            Transformar qualquer ideia em realidade digital. Inovação, agilidade
            e competitividade em cada linha de código. Isso é ser Codice.
          </Styles.FooterParagraph>
          <Styles.Icons>
          <a href={thirdExternalLink}>
            <Styles.Icon src={FbIcon} />
            </a>
            <a href={thirdExternalLink}>
            <Styles.Icon src={GhIcon} />
            </a>
            <a href={thirdExternalLink}>
            <Styles.Icon src={IgIcon} />
            </a>
            <a href={thirdExternalLink}>
            <Styles.Icon src={XIcon} />
            </a>
          </Styles.Icons>
        </Styles.FooterColumn>
        <Styles.FooterColumn>
            <Styles.ColumnTitle>Codice</Styles.ColumnTitle>
            <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                <li onClick={() => scrollToSection('usSection')} style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' , cursor: 'pointer'}}>Sobre nós</li>
                <li onClick={() => scrollToSection('infoSection')} style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' , cursor: 'pointer'}}>O que é ser Codice?</li>
            </ul>
        </Styles.FooterColumn>
        <Styles.FooterColumn>
        <Styles.ColumnTitle>Ajuda</Styles.ColumnTitle>
            <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                <li><a href="#" style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' }}>Sobre nós</a></li>
                <li><a href="#" style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' }}>O que é ser Codice?</a></li>
            </ul>
        </Styles.FooterColumn>
        <Styles.FooterColumn>
        <Styles.ColumnTitle>Portifólio</Styles.ColumnTitle>
            <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                <li><a href="https://xjur.com.br" style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' }}>XJUR</a></li>
                <li><a href="https://infolocklgpd.com.br/" style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' }}>InfoLock</a></li>
                <li><a href="https://lgpdlock.com.br" style={{ color: "#FFFFFF", fontWeight: 400, fontSize: '18px', textDecoration: 'none' }}>LGPD Lock</a></li>
            </ul>
        </Styles.FooterColumn>
      </Styles.FooterContent>
      <Styles.Rights>
        <Styles.RightsText>© 2024 Codice. Todos os direitos reservados. Privacidade</Styles.RightsText>
      </Styles.Rights>
    </Styles.FooterContainer>
  );
}

export default Footer;
